







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    // footerLayout1: () => import('@/components/business/pc/footer/InsFooterLayout1.vue'),
    // footerLayout2: () => import('@/components/business/pc/footer/InsFooterLayout2.vue')
    footerLayout: () => import('@/components/cmxBusiness/pc/footer/InsFooterLayout.vue')
  }
})
export default class InsFooter extends Vue {
  // layout: string = 'footerLayout1';
}
